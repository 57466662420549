
/* Add your profile image here */
.Photo{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/portfolio-4d574.appspot.com/o/carleton.png?alt=media');
}
/* Projects Photo */
.familiarSpiritsPic {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/portfolio-4d574.appspot.com/o/familiar_spirits_wide.jpg?alt=media');
    cursor: none;
};
.project1{
    background-image: url('https://via.placeholder.com/400x600.png?text=Project+one');
    cursor: none;    
}
.project2{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/portfolio-4d574.appspot.com/o/pipes_wide.jpg?alt=media');
    cursor: none;
}
.project3{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/portfolio-4d574.appspot.com/o/gateway_cropped.jpg?alt=media');
    cursor: none;
}
.project4{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/portfolio-4d574.appspot.com/o/me.jpg?alt=media');
    cursor: none;
}
.javaGames{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/portfolio-4d574.appspot.com/o/java_games.jpg?alt=media');
    cursor: none;
}
.myAI{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/portfolio-4d574.appspot.com/o/my_ai.jpg?alt=media');
    cursor: none;
}
/* Miscelaneous Activities photo */
.misc1{
    background-image: url('https://via.placeholder.com/400x600.png?text=Miscellaneous+one');
    cursor: none;
}
.misc2{
    background-image: url('https://via.placeholder.com/400x600.png?text=Miscellaneous+two');
    cursor: none;
}
.misc3{
    background-image: url('https://via.placeholder.com/400x600.png?text=Miscellaneous+three');
    cursor: none;
}